<template>
  <div class="c-app">
    <TheSidebar ref="sidebar" />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <!-- <b-overlay :show="show" no-wrap> -->
          <b-container
            fluid
            :class="[
              $route.name === 'Report Customer Detail'
                ? 'report_customer_detail'
                : '',
            ]"
          >
            <router-view />
            <Loading />
          </b-container>
          <!-- </b-overlay> -->
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import Loading from "@/components/Loading";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    Loading,
  },

  methods: {},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.report_customer_detail {
  padding-right: 15px;
}
#loading {
  background: rgba(255, 255, 255, 0.39);
  .modal-content {
    background: unset;
    position: absolute;
    border: unset;
  }
  .modal-body {
    justify-content: center;
    align-content: center;
    display: flex;
  }
}
</style>
